.navigation {
  .nav {
    background: #f2f2f2;
    border-color: transparent !important;
    color: #000000;

    &:hover {
      background: #f2f2f2;
    }
  }

  .nav-active {
    background: #000000;
    border-color: transparent !important;
    color: #ffffff;

    &:hover {
      background: #000000;
    }
  }

  .nav-item {
    background: #000000;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    margin-left: 1px;
    margin-right: 1px;

    .MuiButton-label {
      padding-top: 0;
    }

    &:hover {
      background: #000000;
      border: none;
    }
  }

  .MuiButton-root {
    min-width: 32px !important; //40px
    border-color: #ddd;
  }

  .MuiButtonGroup-grouped:not(:last-child),
  .MuiButtonGroup-groupedOutlined:not(:first-child) {
    border-radius: 0;
  }
  .MuiButtonGroup-grouped {
    margin: 0;
  }

  .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0;
  }
}
