.box-wrap {
  background-color: white;
  padding: 25px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

  .MuiFormLabel-root {
    white-space: break-spaces;
  }

  .box-title {
    padding-bottom: 5px;
    margin-bottom: 15px;

    .box-right {
      text-align: right;

      .comment-wrap {
        margin: 4px 0 0;
        .comment-status {
          color: red;
        }
      }
    }
    .box-wrap-divider {
      margin: 5px 0px;
    }
  }

  .description-box-table {
    margin-bottom: 0;
  }
}
