.login-form-wrap {
  padding: 32px 56px;

  .login-logo {
    width: 80%;
    display: block;
    margin: 50px auto;
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
  }

  .alert-message {
    padding: 0;
    background: transparent;
    margin-left: -2px;
    .MuiAlert-icon {
      margin-right: 10px;
    }
  }

  .forgot-link {
    margin-top: 20px;
    display: block;
  }

  .register-link {
    margin-top: 20px;
    display: block;
  }

  .language-switch {
    margin: auto;
    text-align: center;
  }

  @media screen and (max-width: 1440px) {
    .login-checkbox {
      .login-checkbox-label {
        font-size: 14px;
      }
    }

    .forgot-link {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1024px) {
    .login-logo {
      margin: 48px auto 32px auto;
    }
  }
}
