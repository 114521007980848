.btn-profile.MuiButton-text {
  background: transparent;
  letter-spacing: 1px;
  padding: 8px;
}

.user-dropdown {
  .MuiPaper-root {
    background-color: #ffffff;

    border-radius: 4px;
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    min-width: 120px;

    .MuiMenuItem-gutters {
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1px;
      justify-content: center;
      padding: 0;

      a {
        color: #000000;
        text-decoration: none;
        padding: 12px 13px;
        width: 100%;
      }
    }

    .MuiListItem-button {
      a {
        padding: 12px 13px;
      }
    }
  }
}
