.input-error {
  svg {
    vertical-align: -6px;
    padding-right: 4px;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    color: #000000;
  }
}
