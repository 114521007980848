.notifications-button {
  .notifications-component {
    color: #ffffff;
  }
}
.notifications-popper {
  .disableScrolling {
    overflow: hidden;
  }
  .notifications-stack {
    background: #ffffff;

    .notifications-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .notification-div {
      cursor: pointer;
      padding: 10px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #00000014;
      border-radius: 5px;

      .icon-action {
        margin-left: auto;
      }
    }
  }
  .empty-notifications {
    margin: 112px auto;
    text-align: center;
    color: #bababa;
  }
  .clear-all-container {
    display: flex;
    justify-content: center;
  }
}
