.language-switch {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}
.language-switch-menu {
  margin-left: -40px;

  &.user-dropdown .MuiPaper-root .MuiMenuItem-gutters.switcher-item {
    padding: 12px;

    &.active {
      color: #e4002b;
    }
    &:hover {
      color: #e4002b;
    }
  }
}
