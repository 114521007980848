.intro-card {
  .intro-container {
    min-height: 528px;
    align-self: center;
  }

  .content-section {
    background: #ffffff;
    position: relative;
  }

  .intro-intro {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    background-position: 100% 100%;
    background-size: 411px;
    background-repeat: no-repeat;

    .intro-message {
      padding: 96px 48px 40px 48px;
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .intro-title {
        line-height: 44px;
        white-space: pre-line;
      }
    }

    .official-url {
      flex: 0;
      text-align: center;
      padding: 16px 32px;
      margin-bottom: 40px;

      a {
        font-weight: 300;
        text-decoration: none;
      }
    }
  }

  .intro-paper.MuiPaper-root {
    background: #e4002b;
  }

  .intro-form {
    background: #ffffff;
  }

  .intro-container {
    .intro-intro {
      .intro-message {
        .intro-title {
          @media screen and (max-width: 1600px) {
            font-size: 32px;
            line-height: 38px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 27px;
            line-height: 33px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1200px) {
    .intro-container {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1279px) {
    .intro-container {
      display: flex;

      .intro-intro {
        .intro-message {
          padding: 80px 48px 40px 48px;
        }
      }
    }
  }
}
