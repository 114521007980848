.mobile-number-dialog {
  .mobile-number-title {
    display: flex;
  }

  .close-button {
    padding: 0;
  }

  .arrow-mui-tel-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    pointer-events: none;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiTelInput-FlagImg {
    margin-right: 20px;
  }
}
