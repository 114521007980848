.two-factor-page {
  padding: 32px 56px;

  .login-logo {
    width: 80%;
    display: block;
    margin: 50px auto;
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
  }

  .resend-code {
    margin-top: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .problem-logging {
    margin-top: 20px;
    display: block;
    text-decoration: none;
  }

  .language-switch {
    margin: auto;
    text-align: center;
  }
}
