.admin {
  .content {
    position: absolute;
    top: 80px;
    left: 70px;
    width: calc(100% - 70px);
    padding: 24px;
    box-sizing: border-box;
    transition: all 0.2s;
    padding-bottom: 100px;

    &.content--open {
      top: 80px;
      left: 252px;
      width: calc(100% - 252px);
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      width: 100%;
      left: 0;

      &.content--open {
        width: 100%;
        left: 0;
      }
    }
  }
}
