body {
  font-family: 'Gotham', sans-serif;
  margin: 0;
  padding: 0;
  // overflow-y: overlay;
  background: #fafafa;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.bottom-toolbar {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .grow {
    flex-grow: 1;
  }

  .per-page {
    padding-top: 8px;

    label {
      margin-right: 8px;
    }

    .MuiSelect-icon {
      top: calc(50% - 12.8px);
    }

    .MuiSelect-selectMenu {
      padding-bottom: 2px;
    }
  }
}

.MuiPickersToolbarButton-toolbarBtn.MuiButton-text {
  background: transparent;
}

.MuiSelect-root.MuiSelect-select:focus {
  background-color: transparent;
}

.MuiPickersDay-root:not(.Mui-selected) {
  border: none !important;
}

.MuiCalendarPicker-root {
  .MuiPickersDay-root {
    font-size: 11px;
    font-family: 'Gotham';
  }

  .MuiDayPicker-weekDayLabel {
    font-size: 11px;
    font-family: 'Gotham';
    color: rgba(0, 0, 0, 0.6);
  }

  .MuiSvgIcon-root {
    color: #e4002b;
  }

  .Mui-selected {
    font-weight: 600 !important;
  }
}
