.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &::before {
    opacity: 0.7;
    background: white; // Theme color
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .loading-bar {
    position: absolute;
    top: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading-wrapper-full {
  position: fixed;
  z-index: 1000;
}
