.terms {
  .MuiTypography-root {
    font-family: 'Gotham';
    strong, &.MuiTypography-body2, & h4 {
      font-family: 'Gotham-Medium';
    }
  }
  
  .MuiDialog-paperScrollPaper {
    overflow: hidden;
  }
  .MuiDialogContent-root {
    padding-bottom: 0;
    max-height: 320px;
  }
  .MuiDialogActions-root {
    position: sticky;
    bottom: 0;
    z-index: 9999;
    background: white;
  }
}
