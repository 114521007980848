.cell-sorter {
  display: inline-block;
  vertical-align: -5.6px;
  margin-left: 4px;

  svg {
    display: block;
    width: 10px;
    cursor: pointer;
    opacity: 0.2;
  }
  span {
    &.active {
      svg {
        opacity: 1;
      }
    }
  }

  @media print {
    display: none !important;
  }

  @media screen and (max-width: 1440px) {
    margin-left: 5px;
    width: 10px;
    .arrow-direction-up,
    .arrow-direction-down {
      width: 10px;
    }
  }
}
