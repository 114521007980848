.reset-password-page {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 44%;
  background-position: -17% 264%;
  display: flex;
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 44%;
  background-position: -17% 264%;

  .reset-form-wrap {
    padding: 32px 56px;

    .reset-input {
      margin: 16px 0;
    }

    .reset-logo {
      margin: 50px auto;
      width: 80%;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      height: 50px;
    }

    .error-message {
      margin-bottom: 20px;
    }

    .reset-button {
      margin-top: 30px;
    }

    .forgot-link {
      margin-top: 16px;
      display: block;
    }

    .language-switch {
      margin: auto;
      text-align: center;
    }
  }
}
