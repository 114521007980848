.page-title {
  min-height: 35px;

  .page-title-container {
    justify-content: space-between;
    gap: 13px;
  }

  .page-title-item {
    display: flex;
    align-content: center;

    .page-title-typography {
      align-self: center;
    }
  }

  .back-button {
    align-self: center;
    vertical-align: -0.8px;
    margin-right: 16px;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      background: #fafafa;
    }
  }

  .title-cell {
    max-width: fit-content;
    text-align: right;
    flex: 1;
  }

  .back-button {
    align-self: center;
    vertical-align: -0.8px;
    margin-right: 16px;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }

  h4 {
    display: inline-block;
    align-self: center;
  }
}
