.recycling-header {
  &.recycling-header--closed {
    .brand {
      width: 34px;
      padding: 0 16px 0 0;
    }
  }

  .MuiToolbar-gutters {
    padding-left: 19px;
    padding-right: 19px;
  }

  .brand {
    border-right: 1px solid #0000001c;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 209px;
    padding: 0 24px 0 0;
    transition: all 0.2s;

    .brand-logo {
      max-width: 100%;
      transition: all 0.2s;
    }
  }

  .grow {
    flex-grow: 1;
  }

  .btn-menu {
    padding: 15px;
    margin-left: 10px;
    cursor: pointer;
    fill: #fffbfb;
    font-size: 30px;
  }

  @media only screen and (max-width: 768px) {
    .brand {
      width: 215px;
    }
    .MuiToolbar-gutters {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
