@font-face {
  font-family: 'Gotham-Light';
  font-style: normal;
  font-weight: 300;
  src:
    url('../../public/fonts/Gotham-Light_Web.eot'),
    url('../../public/fonts/Gotham-Light_Web.woff') format('woff'),
    url('../../public/fonts/Gotham-Light_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../public/fonts/Gotham-Book_Web.eot'),
    url('../../public/fonts/Gotham-Book_Web.woff') format('woff'),
    url('../../public/fonts/Gotham-Book_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Medium';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../public/fonts/Gotham-Medium_Web.eot'),
    url('../../public/fonts/Gotham-Medium_Web.woff') format('woff'),
    url('../../public/fonts/Gotham-Medium_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  font-style: normal;
  font-weight: 600;
  src:
    url('../../public/fonts/Gotham-Bold_Web.eot'),
    url('../../public/fonts/Gotham-Bold_Web.woff') format('woff'),
    url('../../public/fonts/Gotham-Bold_Web.woff2') format('woff2');
  font-display: swap;
}
