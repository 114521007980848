.upload-dropzone {
  background: white;
  margin-top: 10px;

  & > div:focus {
    outline: none;
  }

  p {
    border: 2px dashed rgba(0, 0, 0, 0.1);
    background: rgba(228, 0, 43, 0.02);
    text-align: center;
    padding: 32px 0;
    outline: none;
    cursor: pointer;
  }

  img {
    margin: 0 auto 8px auto;
    display: block;
  }
}

.upload-box {
  .files {
    .single-file {
      display: flex;
      flex-direction: row;

      img {
        flex: 0;
        margin-right: 8px;
      }

      display: inline-block;
      height: 48px;
      font-size: 40px;
      margin-right: 8px;

      .file-content {
        flex: 1;
        align-self: center;

        .file-name {
          margin-bottom: 12px;
        }
      }
    }
  }
}
