.sidebar {
  position: relative;

  &.sidebar--open {
    .sidebar-item {
      padding: 0;

      a {
        color: #000000;
        display: flex;
        padding: 16px 0 16px 16px;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    ul {
      animation: menuIntro 2s;
    }
  }

  &.sidebar--closed {
    .MuiDrawer-paper {
      width: 70px;
    }

    .MuiListItemText-root {
      display: none;
      opacity: 0;
    }

    .MuiListItemIcon-root {
      justify-content: center;
      min-width: 100%;
    }

    .sidebar-icon {
      height: 28px;
    }

    .sidebar-item {
      margin: 0 auto;

      a {
        width: 100%;
      }
    }

    .MuiListItem-button {
      padding-top: 0;
      padding-bottom: 0;
    }

    .bottom-links {
      display: none;
    }
  }

  .MuiDrawer-paper {
    top: 80px;
    width: 251.2px;
    height: calc(100% - 80px);
    box-shadow: 1px 0 5px -5px black;
    transition: width 0.2s;
  }

  .sidebar-icon {
    color: black; // Themes color

    #Shape {
      fill: black; // Themes color
    }
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }

  .MuiSvgIcon-root {
    width: 0.9em;
    height: 0.9em;
  }

  .MuiListItemText-root {
    .MuiTypography-body1 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 1px;
      display: inline-block;
      line-height: 1.2;
    }
  }

  .MuiListItem-button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-list {
    margin: 0;
    padding: 0;
  }

  .item--selected {
    .MuiListItemText-root,
    .sidebar-icon {
      color: #e4002b;

      #Shape {
        fill: #e4002b;
      }
    }
  }

  .bottom-links {
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 48px;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 998;
    font-family: 'Gotham-Medium';

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 1px;
      border-top: 1px solid #eceeef;
      transform: translateX(5%);
    }

    li {
      list-style: none;
      margin: 0;
      padding: 0;
      text-decoration: none;
      color: #000;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 6px;
      cursor: pointer;
      display: flex;
      align-self: center;
    }
  }

  @media only screen and (max-width: 768px) {
    &.sidebar--closed {
      .MuiDrawer-paper {
        left: -72px;
      }
    }
  }
}

@keyframes menuIntro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
